import { lazy, Suspense } from 'react';
import { createRoutesFromElements, Route, useNavigate } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'urql';
import EditBillingInfo from '@ashn/fitsubscription-react/BillingInfo';
import generateCheckInRoutes from '@ashn/check-ins-react/generateCheckInRoutes';
import LandingPage from '../../pages/LandingPage/LandingPage';
import ErrorNotFound from '../../pages/ErrorNotFound';
import MyAccount from '../../pages/MyAccount/MyAccount';
import { myAccountRoutes } from '../../pages/MyAccount/myAccountRoutes';
import CheckIns from '../../pages/CheckIns/CheckIns';
import ProtectedPage from '../../components/routing/ProtectedPage';
import PageSEO from '../../components/shared/PageSEO';
import UserQuery from '../../queries/user';
import getEnvironment from '../getEnvironment';
import getSsid from '../getSsid';
import { windowLocation } from '../windowLocation';
import redirectUnknownRoute from '../redirectUnknownRoute';

const Billing = lazy(() => import('@ashn/fitsubscription-react/Billing'));

const Survey = lazy(() => import('../../pages/Survey/Survey'));
const Contact = lazy(() => import('../../pages/Contact/Contact'));
const FAQ = lazy(() => import('../../pages/FAQ/FAQ'));

let userData;
const BillingRoute = () => {
	const navigate = useNavigate();
	const [result] = useQuery({
		query: UserQuery,
		variables: { id: 0, tenant: 'activeandfit' },
	});
	const { data } = result;
	const fitnessMember = data?.fitnessMember;
	userData = fitnessMember;

	return (
		fitnessMember && (
			<ProtectedPage>
				<PageSEO pageTitle="Billing & Payments" />
				<Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
					<Billing
						isAdmin={false}
						memberId={`${fitnessMember.fitnessId}`}
						onEditBillingClick={() => navigate('editBilling')}
						ErrorComponent={ErrorNotFound}
					/>
				</Suspense>
			</ProtectedPage>
		)
	);
};

const generatedRoutes = createRoutesFromElements(
	<Route
		path="MyAccount/billing/*"
		label="billing"
		element={<MyAccount />}
		id="500"
	>
		<Route index path="*" id="500-1" element={<BillingRoute />} />
		<Route
			path="editBilling"
			element={
				<ProtectedPage>
					<PageSEO pageTitle="Edit Billing Info" />
					<Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
						<EditBillingInfo
							recurlySiteCode={'afe'}
							isProduction={getEnvironment() === 'prod'}
							memberId={`${userData?.fitnessId}`}
						/>
					</Suspense>
				</ProtectedPage>
			}
			id="500-2"
		/>
	</Route>,
);

const useRouteList = () => {
	const ssId = getSsid();
	const apiEnv = getEnvironment();
	const encodedUrl = encodeURIComponent(window.location.origin);
	const checkInData = {
		apiEnv,
		ssId,
		redirectToFitnessCenterVisitsPage: () => {
			windowLocation('/Connected/CheckIns');
		},
		redirectToLoginPage: () => {
			windowLocation(
				`/identity/login?redirect=${encodedUrl}/CheckIns/LandingPage`,
			);
		},
	};

	return [
		{
			anchor: '#howItWorks',
			claim: null,
			element: <LandingPage />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'howItWorks',
			label: 'HOW IT WORKS',
			path: '/',
			showHeaderMenu: true,
		},
		{
			anchor: '#fitnessCenters',
			claim: null,
			element: <LandingPage />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'fitnessCenters',
			label: 'FITNESS CENTERS',
			path: '/',
			showHeaderMenu: true,
		},
		{
			anchor: '#workouts',
			claim: null,
			element: <LandingPage />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'workouts',
			label: 'WORKOUTS',
			path: '/',
			showHeaderMenu: true,
		},
		{
			anchor: '#homeFitnessKits',
			claim: null,
			element: <LandingPage />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'homeFitnessKits',
			label: 'HOME FITNESS KITS',
			path: '/',
			showHeaderMenu: true,
		},
		{
			anchor: null,
			claim: null,
			element: (
				<Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
					<FAQ />
				</Suspense>
			),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'faqJump',
			label: 'FAQ',
			path: '/FAQ',
			showHeaderMenu: true,
		},
		{
			anchor: '#healthPlans',
			claim: null,
			element: <LandingPage />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'healthPlans',
			label: 'HEALTH PLANS/EMPLOYERS',
			path: '/',
			showHeaderMenu: true,
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			isProtected: true,
			key: 'dashboard',
			label: 'Dashboard',
			path: '/dashboard',
			showHeaderMenu: true,
		},
		{
			claim: 'connectedV2',
			element: null,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			isProtected: true,
			key: 'connected',
			label: 'Connected!',
			path: null,
			children: [
				{
					claim: 'connectedV2',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					key: 'connecteddashboard',
					label: 'Activity',
					path: '/Connected/ActivityTracker',
				},
				{
					claim: 'connectedV2',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					key: 'connectedappsanddevices',
					label: 'Apps and Devices',
					path: '/Connected/Devices',
				},
				{
					claim: 'connectedV2',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					label: 'Fitness Center Visits',
					path: '/Connected/CheckIns',
				},
			],
		},

		{
			claim: 'checkIn',
			element: <CheckIns />,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			isProtected: true,
			key: 'check-in',
			label: 'Check-In',
			path: 'CheckIns',
			children: [
				{
					claim: 'checkIn',
					element: null,
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					key: 'checkInsLandingPage',
					label: 'Check-In',
					path: 'CheckIns/LandingPage',
				},
				...generateCheckInRoutes(checkInData),
				{
					claim: 'checkIn',
					element: null,
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					key: 'checkInsHowTo',
					label: 'How to Use Check-In',
					path: 'CheckIns/HowToUse',
				},
				{
					claim: 'checkIn',
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					key: 'checkinFitnessCenterVisits',
					label: 'Fitness Center Visits',
					path: 'Connected/CheckIns',
				},
			],
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			isProtected: true,
			isExternalLink: false,
			key: 'networkSearch',
			label: 'Fitness Centers',
			path: '/search',
			gaLabel: 'fitness_centers',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			isProtected: true,
			key: 'digitalWorkouts',
			label: 'Workouts',
			path: '/ResourceLibrary/workouts',
			showHeaderMenu: true,
		},
		{
			claim: 'atHomeMembership',
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			isProtected: true,
			isExternalLink: false,
			key: 'homeKits',
			label: 'Home Kits',
			gaLabel: 'home_kits',
			path: '/kitSelection',
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			isProtected: true,
			key: 'resources',
			label: 'Resources',
			path: null,
			showHeaderMenu: true,
			children: [
				{
					claim: null,
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					key: 'resourcesResourceLibrary',
					label: 'Resource Library',
					path: '/ResourceLibrary',
					showHeaderMenu: true,
				},
				{
					claim: null,
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					key: 'resourcesNewsletters',
					label: 'Newsletters',
					path: '/newsletters',
					showHeaderMenu: true,
				},
			],
		},
		{
			claim: 'incentivesPlan',
			element: null,
			includeInFooter: false,
			includeInHeader: true,
			inclueInLoggedInHeader: true,
			includeInProfile: false,
			isProtected: true,
			key: 'rewards',
			label: 'Rewards',
			path: null,
			showHeaderMenu: true,
			children: [
				{
					claim: null,
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					key: 'rewardsFitnessActivity',
					label: 'Fitness Activity',
					path: '/incentives/RewardsProgram',
					showHeaderMenu: true,
				},
				{
					claim: null,
					element: null,
					loader: ({ request: { url } }) => redirectUnknownRoute(url),
					includeInFooter: false,
					includeInHeader: true,
					inclueInLoggedInHeader: true,
					includeInProfile: false,
					isProtected: true,
					key: 'rewardsRewards',
					label: 'Rewards',
					path: '/incentives/Rewards',
					showHeaderMenu: true,
				},
			],
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'aboutus',
			label: 'About Us',
			path: '/About',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'terms',
			label: 'Terms & Conditions',
			path: '/TermsAndConditions',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'privacy',
			label: 'Privacy Statement',
			path: '/Privacy',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'faq',
			label: 'FAQ',
			path: '/FAQ',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: (
				<Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
					<Contact />
				</Suspense>
			),
			includeInFooter: true,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'contactus',
			label: 'Contact Us',
			path: '/contactus',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: (
				<ProtectedPage>
					<MyAccount />
				</ProtectedPage>
			),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: true,
			isProtected: true,
			key: 'myaccount',
			label: null,
			path: '/MyAccount',
			children: [...myAccountRoutes],
		},
		{
			claim: null,
			element: (
				<Suspense fallback={<Skeleton variant="rectangular" height={600} />}>
					<Survey />
				</Suspense>
			),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: true,
			key: 'survey',
			label: null,
			path: '/survey',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'identity-login',
			label: 'Identity Login',
			path: '/identity/login',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'identity-registration',
			label: 'Identity Registration',
			path: '/identity/registration',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: true,
			key: 'subscription-payment-empty',
			label: 'Subscription Cart',
			path: '/subscription',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: null,
			loader: ({ request: { url } }) => redirectUnknownRoute(url),
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: true,
			key: 'logout',
			label: 'Log Out',
			path: '/logout',
			showHeaderMenu: false,
		},
		{
			claim: null,
			element: <ErrorNotFound />,
			includeInFooter: false,
			includeInHeader: false,
			inclueInLoggedInHeader: false,
			includeInProfile: false,
			isProtected: false,
			key: 'notFound',
			label: '404',
			path: '*',
			showHeaderMenu: false,
		},
		...generatedRoutes,
	];
};

export default useRouteList;
