import PropTypes from 'prop-types';
import { useState, lazy, Suspense } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useQuery } from 'urql';
// MUI
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import OutlinedInput from '@mui/material/OutlinedInput';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
// Material Icons
import AddBoxIcon from '@mui/icons-material/AddBox';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HomeIcon from '@mui/icons-material/Home';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
// Image helpers
import {
	BoxBackground,
	PictureHelper,
} from '../../components/imageHelpers/ImageHelpers';
// GQL queries
import UserQuery from '../../queries/user';
// Social icons
import logoFacebookLive from './images/logoFacebookLive.svg';
import logoYoutube from './images/logoYoutube.svg';
// Image lists
import {
	connectedBg,
	fitnessLogos,
	heroBg,
	homeKitsBg,
	howItWorksBg,
	onDemandLogos,
	onDemandVideosBg,
} from './imageLists';
import SiteWarning from '../../components/shared/SiteWarning/SiteWarning';

const LazyLocationSearch = lazy(() => import('./temporary/LocationSearch'));
/* 
- TODO Possibly extract UI patterns
*/

const SkeletonNumber = ({ amount = null }) => {
	if (amount) {
		return `${amount.toLocaleString('en-us')}+`;
	}
	return <Skeleton width="3rem" sx={{ display: 'inline-block' }} />;
};
SkeletonNumber.propTypes = {
	amount: PropTypes.number,
};

// This is the home page for users not logged in.
const LandingPage = () => {
	const [videoOpen, videoOpenSet] = useState(false);
	const navigate = useNavigate();
	const theme = useTheme();
	const isXl = useMediaQuery(theme.breakpoints.up('xl'));
	const isLg = useMediaQuery(theme.breakpoints.up('lg'));
	const isMd = useMediaQuery(theme.breakpoints.up('md'));
	const [result] = useQuery({
		query: UserQuery,
		variables: { id: 0, tenant: 'activeandfit' },
	});
	const { data = {} } = result;

	if (data?.fitnessMember) {
		// eslint-disable-next-line no-unused-expressions
		data.fitnessMember.fitnessId > 0 ? navigate(`dashboard`) : null;
	}
	// TODO Until we go to the real GQL calls, all of these hostProperties will
	// be strings so we need to coerce them to numeric
	const itemCounts = {
		digitalWorkoutsVideoCount: Number(
			data?.fitnessMember?.hostProperties?.find(
				i => i.key === 'digitalWorkoutsVideoCount',
			).value,
		),
		premiumFacilitiesCount: Number(
			data?.fitnessMember?.hostProperties?.find(
				i => i.key === 'premiumFacilitiesCount',
			).value,
		),
		standardFacilitiesCount: Number(
			data?.fitnessMember?.hostProperties?.find(
				i => i.key === 'standardFacilitiesCount',
			).value,
		),
	};

	return (
		<SiteWarning message={data?.fitnessMember?.siteWarning}>
			{/* Top hero */}
			<BoxBackground
				images={!isMd ? [] : heroBg}
				sx={{
					backgroundPosition: 'right',
					backgroundSize: 'cover',
				}}
			>
				<Grid
					container
					color={{ xs: '', md: 'primary.contrastText' }}
					columnSpacing={2}
					direction="row"
					justifyContent={{ xs: 'center', md: 'flex-start' }}
					py={{ md: 15 }}
					pb={{ xs: 5, md: 15 }}
				>
					{!isMd ? (
						<Grid item xs={12}>
							<div>
								<PictureHelper
									alt=""
									images={heroBg}
									style={{ width: '100%' }}
								/>
							</div>
						</Grid>
					) : (
						<Grid item md={1} />
					)}
					<Grid item xs={10} md={5} xl={4}>
						<Typography variant="h2" component="h1">
							Getting active just got easier&trade;
						</Typography>
						<Typography my={3}>
							Access{' '}
							<SkeletonNumber
								amount={
									(itemCounts.premiumFacilitiesCount || 0) +
									(itemCounts.standardFacilitiesCount || 0)
								}
							/>{' '}
							fitness centers and studios,{' '}
							<SkeletonNumber amount={itemCounts.digitalWorkoutsVideoCount} />{' '}
							on-demand workout videos, home fitness kits, free daily workouts,
							lifestyle coaching, and more—available through your health plan or
							employer.*
						</Typography>
						<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
							<Button
								onClick={() => videoOpenSet(true)}
								variant="outlined"
								sx={{
									borderColor: { xs: '', md: 'primary.contrastText' },
									color: { xs: '', md: 'primary.contrastText' },
								}}
							>
								<PlayCircleOutlineIcon sx={{ marginRight: 1 }} />
								WATCH VIDEO
							</Button>
							{/* Modal for the video */}
							<Dialog
								fullWidth
								maxWidth="lg"
								open={videoOpen}
								onClose={() => videoOpenSet(false)}
								aria-labelledby="video-modal-title"
							>
								<DialogTitle id="video-modal-title">
									Active&amp;Fit Product Overview
								</DialogTitle>
								<DialogContent>
									<div
										style={{ padding: '56.25% 0 0 0', position: 'relative' }}
									>
										<iframe
											allow="autoplay; fullscreen; picture-in-picture"
											allowFullScreen
											frameBorder="0"
											src="https://player.vimeo.com/video/148578024?h=614d706041&title=0&byline=0&portrait=0"
											style={{
												position: 'absolute',
												top: 0,
												left: 0,
												width: '100%',
												height: '100%',
											}}
											title="Active&Fit Product Overview"
										/>
									</div>
								</DialogContent>
								<DialogActions>
									<Button onClick={() => videoOpenSet(false)} autoFocus>
										CLOSE
									</Button>
								</DialogActions>
							</Dialog>
							<Button
								component={Link}
								href="/identity/registration"
								variant="contained"
								color="secondary"
							>
								CHECK ELIGIBILITY
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</BoxBackground>
			{/* Search bar */}
			<Box color="primary.contrastText" bgcolor="primary.main" py={3}>
				<Grid
					container
					alignItems="center"
					direction="row"
					justifyContent="center"
					spacing={2}
				>
					<Grid item xs={10} md={3} align={isMd ? 'right' : 'left'}>
						<Typography variant="body3">
							Find a fitness center near you:
						</Typography>
					</Grid>
					<Grid item xs={10} md={7}>
						<FormControl
							variant="outlined"
							sx={{
								backgroundColor: 'primary.contrastText',
								borderRadius: '4px',
								width: '100%',
							}}
						>
							<Suspense
								fallback={<Skeleton variant="rectangular" height={20} />}
							>
								<OutlinedInput
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="search fitness centers"
												edge="end"
											>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									}
									id="network-search-input"
									inputComponent={LazyLocationSearch}
									placeholder="City, State, or ZIP Code"
									sx={{
										'& div:nth-of-type(2)': { width: '100%' },
									}}
								/>
							</Suspense>
						</FormControl>
					</Grid>
				</Grid>
			</Box>
			{/* Gym logos */}
			<Box px={{ xs: 1 }} py={3} sx={{ bgcolor: 'grey.100' }}>
				<Grid
					container
					alignItems="baseline"
					direction="row"
					justifyContent="center"
					spacing={2}
				>
					<Grid item xs={10}>
						<PictureHelper
							alt="Fitness center logos"
							images={fitnessLogos}
							style={{ width: '100%' }}
						/>
					</Grid>
					<Grid item xs={10}>
						<Typography align="center">
							Find these brands and more! Fitness center participation varies by
							location.
						</Typography>
					</Grid>
				</Grid>
			</Box>
			{/* Membership types */}
			<Box py={10}>
				<Grid
					container
					alignItems="baseline"
					direction="row"
					justifyContent="center"
					spacing={2}
				>
					<Grid item xs={10} md={4} lg={3}>
						<Stack alignItems="center" spacing={2}>
							<Avatar
								alt=""
								sx={{
									bgcolor: 'primary.light',
									color: 'primary.dark',
									height: 80,
									width: 80,
								}}
							>
								<FitnessCenterIcon
									sx={{
										height: 40,
										width: 40,
									}}
								/>
							</Avatar>
							<Typography align="center" variant="h5" component="h2">
								Fitness Center Memberships
							</Typography>
							<Typography align="center">
								Choose from our network of{' '}
								<SkeletonNumber amount={itemCounts.standardFacilitiesCount} />{' '}
								Standard and{' '}
								<SkeletonNumber amount={itemCounts.premiumFacilitiesCount} />{' '}
								Premium fitness center memberships.* Enjoy the flexibility of
								changing your fitness center monthly to find one that best suits
								your lifestyle.
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={10} md={2} xl={1.5} sx={{ textAlign: 'center' }}>
						<hr
							style={{
								transform: !isMd ? '' : 'rotate(0.25turn) translateX(100px)',
							}}
						/>
					</Grid>
					<Grid item xs={10} md={4} lg={3}>
						<Stack alignItems="center" spacing={2}>
							<Avatar
								alt=""
								sx={{
									bgcolor: 'primary.light',
									color: 'primary.dark',
									height: 80,
									width: 80,
								}}
							>
								<HomeIcon
									sx={{
										height: 40,
										width: 40,
									}}
								/>
							</Avatar>
							<Typography variant="h5" component="h2">
								Fit at Home&trade;
							</Typography>
							<Typography align="center">
								Exercise in the comfort and safety of your home by choosing one
								Home Fitness Kit each benefit year,{' '}
								<SkeletonNumber amount={itemCounts.digitalWorkoutsVideoCount} />{' '}
								on-demand workout videos, and free daily workouts.
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</Box>
			{/* How it works */}
			<BoxBackground
				id="howItWorks"
				images={howItWorksBg}
				py={10}
				sx={{
					backgroundSize: 'cover',
				}}
			>
				<Grid
					container
					alignItems="baseline"
					color="primary.contrastText"
					direction="row"
					justifyContent="center"
					spacing={4}
				>
					<Grid item xs={12}>
						<Typography align="center" component="h1" variant="h2">
							How it Works
						</Typography>
					</Grid>
					<Grid item xs={10} md={3}>
						<Stack alignItems="center" spacing={2}>
							<Avatar
								alt=""
								sx={{
									bgcolor: 'white',
									color: 'primary.main',
									height: 45,
									width: 45,
								}}
							>
								1
							</Avatar>
							<Typography align="center" variant="h5" component="h2">
								Check Your Eligibility
							</Typography>
							<Typography align="center">
								Check your eligibility to explore the program features that your
								health plan or employer has made available to you. Fees depend
								on your benefit plan.
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={10} md={3} sx={{ textAlign: 'center' }}>
						<Stack alignItems="center" spacing={2}>
							<Avatar
								alt=""
								sx={{
									bgcolor: 'white',
									color: 'primary.main',
									height: 45,
									width: 45,
								}}
							>
								2
							</Avatar>
							<Typography align="center" variant="h5" component="h2">
								Explore Your Options
							</Typography>
							<Typography align="center">
								Choose from thousands of fitness centers and studios, and select
								a home fitness kit. Also enjoy{' '}
								<SkeletonNumber amount={itemCounts.digitalWorkoutsVideoCount} />{' '}
								on-demand workout videos.
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={10} md={3}>
						<Stack alignItems="center" spacing={2}>
							<Avatar
								alt=""
								sx={{
									bgcolor: 'white',
									color: 'primary.main',
									height: 45,
									width: 45,
								}}
							>
								3
							</Avatar>
							<Typography align="center" variant="h5" component="h2">
								Work Out & Stay Connected!
							</Typography>
							<Typography align="center">
								Work out at home or your selected fitness center, and use the
								Active&Fit Enterprise Connected!&trade; tool to track your
								activity and exercise goals.
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</BoxBackground>
			{/* Explore fitness centers */}
			<Grid
				container
				id="fitnessCenters"
				justifyContent="center"
				py={10}
				spacing={4}
				sx={{ bgcolor: 'grey.100' }}
			>
				<Grid item xs={10} md={7}>
					<Typography align="center" variant="h2" component="h1">
						Explore Thousands of Fitness Centers
					</Typography>
				</Grid>
				<Grid item xs={10} md={8} lg={7}>
					<Typography align="center">
						Members can choose from our network of{' '}
						<SkeletonNumber amount={itemCounts.standardFacilitiesCount} />{' '}
						Standard and{' '}
						<SkeletonNumber amount={itemCounts.premiumFacilitiesCount} />{' '}
						Premium fitness center memberships to find the workout that's right
						for you.{' '}
						<Link color="secondary" component={NavLink} to="/identity/login">
							Log in
						</Link>{' '}
						or{' '}
						<Link
							color="secondary"
							component={NavLink}
							to="/identity/registration"
						>
							check your eligibility
						</Link>{' '}
						to see which of these features and participating fitness centers are
						available to you.
					</Typography>
				</Grid>
				<Grid item xs={10} md={8} lg={7}>
					<Stack
						alignItems="center"
						direction={{ xs: 'column', md: 'row' }}
						justifyContent="center"
						spacing={2}
					>
						<Typography variant="body3">
							Find a fitness center near you:
						</Typography>
						{/* <Stack alignItems="center" direction="row" justifyContent="center"> */}
						<FormControl
							sx={{
								backgroundColor: 'primary.contrastText',
								width: { xs: '100%', md: '50%' },
							}}
							variant="outlined"
						>
							<Suspense
								fallback={<Skeleton variant="rectangular" height={20} />}
							>
								<OutlinedInput
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												aria-label="search fitness centers"
												edge="end"
											>
												<SearchIcon />
											</IconButton>
										</InputAdornment>
									}
									id="network-search-input-explore"
									inputComponent={LazyLocationSearch}
									placeholder="City, State, or ZIP Code"
									sx={{ '& div:nth-of-type(2)': { width: '100%' } }}
								/>
							</Suspense>
						</FormControl>
						{/* </Stack> */}
					</Stack>
				</Grid>
				<Grid item xs={10} md={8} lg={7}>
					<Typography align="center" component="p" variant="caption">
						*Please note that fitness centers, amenities, and classes vary by
						location and benefit plan. Not all fitness locations participate.
						Any nonstandard fitness center services that typically require an
						additional fee are not included. Not all locations will be offered
						by all health plans. Premium access varies by health plan and
						location. Fees vary by Premium location. Fitness locations are
						subject to price and network change. Terms and conditions apply.
					</Typography>
				</Grid>
			</Grid>
			{/* On-Demand workout videos */}
			<Grid
				container
				alignItems="center"
				id="workouts"
				justifyContent={{ xs: 'center', md: 'flex-start' }}
				my={{ xs: 4, xl: 8 }}
			>
				{isMd ? (
					<Grid item md={1} />
				) : (
					<Grid item xs={12} md={6}>
						<PictureHelper
							alt=""
							images={onDemandVideosBg}
							style={{ width: '100%' }}
						/>
					</Grid>
				)}
				<Grid item xs={10} md={5} align="center">
					<Stack
						alignItems={{ xs: 'center', md: 'flex-start' }}
						direction="column"
						mb={5}
						spacing={4}
					>
						<Typography
							variant="h2"
							component="h1"
							sx={{ textAlign: { xs: 'center', md: 'left' } }}
						>
							On-Demand Workout Videos
						</Typography>
						<Typography sx={{ textAlign: { xs: 'center', md: 'left' } }}>
							Choose from categories including No-Equipment Workouts, Cardio,
							Strength, Dance, Martial Arts, Mind/Body, High-Intensity Interval
							Training, and Cycling. Find classes for your specific fitness
							level at varying lengths, whether you're just getting started or a
							workout guru.
						</Typography>
						<Typography sx={{ textAlign: { xs: 'center', md: 'left' } }}>
							<strong>
								Videos feature workouts by top fitness brands and names:
							</strong>
						</Typography>
						<PictureHelper alt="" images={onDemandLogos} />
						<Button
							component={Link}
							href="/identity/registration"
							variant="contained"
						>
							CHECK ELIGIBILITY
						</Button>
					</Stack>
				</Grid>
				{isMd && (
					<Grid item xs={12} md={6} align="right">
						<PictureHelper
							alt=""
							images={onDemandVideosBg}
							style={{ width: (isXl && '70%') || (isLg && '80%') || '100%' }}
						/>
					</Grid>
				)}
			</Grid>
			{/* Free daily workouts */}
			<Grid
				container
				bgcolor="primary.main"
				color="primary.contrastText"
				justifyContent="center"
				mt={1}
				py={8}
				spacing={2}
			>
				<Grid item xs={10} md={8} align="center">
					<Typography variant="h2" component="h1">
						Free Daily Workouts
					</Typography>
				</Grid>
				<Grid item xs={10} md={8} align="center">
					<Typography>
						Members and non-members are invited to join us on Facebook Live and
						YouTube every Monday - Friday for various types and levels of
						workouts that are open to the public. Try cardio, yoga, strength
						training, and more. Invite your friends to join online, too!
					</Typography>
				</Grid>
				<Grid item xs={10} md={8} align="center">
					<Stack
						alignItems="flex-end"
						direction="row"
						justifyContent="center"
						spacing={8}
					>
						<Link
							color="primary.contrastText"
							href="https://bit.ly/YouTubeMyActiveandFit"
							rel="noopener"
							target="_blank"
						>
							<Stack direction="column" alignItems="center" spacing={1}>
								<img alt="" src={logoYoutube} width="40px" />
								<Typography>YouTube</Typography>
							</Stack>
						</Link>
						<Link
							color="primary.contrastText"
							href="https://www.facebook.com/myactiveandfit"
							rel="noopener"
							target="_blank"
						>
							<Stack direction="column" alignItems="center" spacing={1}>
								<img alt="" src={logoFacebookLive} width="40px" />
								<Typography>Facebook</Typography>
							</Stack>
						</Link>
					</Stack>
				</Grid>
			</Grid>
			{/* Home fitness kits */}
			<BoxBackground
				images={!isMd ? [] : homeKitsBg}
				sx={{
					backgroundPosition: 'left',
					backgroundSize: 'cover',
				}}
			>
				<Grid
					container
					id="homeFitnessKits"
					justifyContent={{ xs: 'center', md: 'flex-start' }}
					py={{ xs: 0, md: 15, xl: 20 }}
				>
					<Grid item xs={12} md={6}>
						{!isMd && (
							<PictureHelper
								alt=""
								images={homeKitsBg}
								style={{ width: '100%' }}
							/>
						)}
					</Grid>
					<Grid item xs={10} md={6}>
						<Stack
							alignItems={{ xs: 'center', md: 'flex-start' }}
							direction="column"
							mb={2}
							spacing={4}
							sx={{ width: { xs: '100%', md: '80%' } }}
						>
							<Typography
								variant="h2"
								component="h1"
								sx={{ textAlign: { xs: 'center', md: 'left' } }}
							>
								Home Fitness Kits
							</Typography>
							<Typography sx={{ textAlign: { xs: 'center', md: 'left' } }}>
								Members can exercise in the comfort and convenience of their
								homes by choosing one kit per benefit year from Wearable Fitness
								Tracker, Pilates, Strength, Swim, and Yoga Kit options.
							</Typography>
							<Button
								component={Link}
								href="/identity/registration"
								variant="contained"
							>
								CHECK ELIGIBILITY
							</Button>
						</Stack>
					</Grid>
				</Grid>
			</BoxBackground>
			{/* Connected */}
			<BoxBackground
				images={connectedBg}
				sx={{
					backgroundPosition: 'right',
					backgroundSize: 'cover',
				}}
			>
				<Grid
					container
					color="white"
					direction="row"
					id="connected"
					justifyContent={{ xs: 'center', md: 'flex-start' }}
					py={{ xs: 10, xl: 20 }}
				>
					{isMd && <Grid item md={1} />}
					<Grid item xs={10} md={6} lg={5} xl={4} align="center">
						<Stack
							alignItems={{ xs: 'center', md: 'flex-start' }}
							direction="column"
							spacing={4}
						>
							<Typography
								variant="h2"
								component="h1"
								sx={{ textAlign: { xs: 'center', md: 'left' } }}
							>
								Track your activity with the Active&Fit Enterprise{' '}
								<Box component="span" sx={{ whiteSpace: 'nowrap' }}>
									Connected!&trade;
								</Box>{' '}
								tool
							</Typography>
							<Typography sx={{ textAlign: { xs: 'center', md: 'left' } }}>
								Sync your favorite wearable fitness tracker or mobile app to
								track your progress.
							</Typography>
						</Stack>
					</Grid>
				</Grid>
			</BoxBackground>
			{/* Partners */}
			<Grid
				container
				alignItems="baseline"
				direction="row"
				id="healthPlans"
				justifyContent="center"
				py={10}
				spacing={2}
				sx={{ bgcolor: 'grey.100' }}
			>
				<Grid item xs={10}>
					<Typography variant="h2" component="h1" align="center">
						Are You a Health Plan, Employer, or Fitness Center?
					</Typography>
				</Grid>
				<Grid item xs={10} md={4} lg={3}>
					<Stack alignItems="center" spacing={2}>
						<Avatar
							alt=""
							sx={{
								bgcolor: 'primary.light',
								color: 'primary.dark',
								height: 80,
								width: 80,
							}}
						>
							<AddBoxIcon
								sx={{
									height: 40,
									width: 40,
								}}
							/>
						</Avatar>
						<Typography align="center" variant="h5" component="h2">
							Health Plans / Employers
						</Typography>
						<Typography align="center">
							We offer health plans and employers flexible program designs to
							fit any budget. Attract new members, make an impact on their
							health, and improve your bottom line.{' '}
							<Link
								color="secondary"
								href="/Resources/ActiveAndFit/HealthPlans"
							>
								Learn more
							</Link>
							.
						</Typography>
					</Stack>
				</Grid>
				<Grid item xs={8} md={2} xl={1.5} sx={{ textAlign: 'center' }}>
					<hr
						style={{
							transform: !isMd ? '' : 'rotate(0.25turn) translateX(100px)',
						}}
					/>
				</Grid>
				<Grid item xs={10} md={4} lg={3}>
					<Stack alignItems="center" spacing={2}>
						<Avatar
							alt=""
							sx={{
								bgcolor: 'primary.light',
								color: 'primary.dark',
								height: 80,
								width: 80,
							}}
						>
							<HomeWorkIcon
								sx={{
									height: 40,
									width: 40,
								}}
							/>
						</Avatar>
						<Typography variant="h5" component="h2">
							Fitness Centers
						</Typography>
						<Typography align="center">
							Interested in becoming part of our network? Reach more members
							across the country through participating groups.{' '}
							<Link
								color="secondary"
								href="https://www.ashlink.com/ASH/public/Providers/Network/joinfitness.aspx"
							>
								Learn more
							</Link>
							.
						</Typography>
					</Stack>
				</Grid>
			</Grid>
			{/* Have more questions */}
			<Grid container justifyContent="center" id="faq" py={10} spacing={4}>
				<Grid item xs={11} md={7}>
					<Typography align="center" variant="h2" component="h1">
						Have More Questions
					</Typography>
				</Grid>
				<Grid item xs={11} md={7}>
					<Typography align="center">
						<Button component={NavLink} to="/FAQ" variant="contained">
							READ OUR FAQ
						</Button>
					</Typography>
				</Grid>
			</Grid>
		</SiteWarning>
	);
};
export default LandingPage;
