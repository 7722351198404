import Cookies from 'js-cookie';
import { Client, cacheExchange, fetchExchange, dedupExchange } from 'urql';
import { authExchange } from '@urql/exchange-auth';
import getSsid from './utils/getSsid';
import getEnvironment from './utils/getEnvironment';

/**
 * This file contains functionality used by the React `index.jsx` page
 * so that it can be tested separately.
 */

window.sessionStorage.clear();
const env = getEnvironment();
const environmentPrefix = env === 'prod' ? '' : `${env}.`;
const ssId = getSsid();

// Utility functions
function logFrom(from, message, ...args) {
	// eslint-disable-next-line no-console
	console.log(
		`%c${from}:%c ${message}`,
		'background-color: lightblue; color: black; font-weight: bold;',
		'background-color: white; color: black;',
		...args,
	);
}

/**
 * Fetch the short-lived token from cache.
 */
export function getCachedToken() {
	const ashToken = window.sessionStorage.getItem('sessionBlob');
	logFrom(
		'getCachedToken',
		`Fetching current sessionBlob from session cache: ${ashToken}`,
	);
	return ashToken;
}
export function setCachedToken(newToken) {
	return window.sessionStorage.setItem('sessionBlob', newToken);
}
export function clearCachedToken() {
	return window.sessionStorage.removeItem('sessionBlob');
}

/**
 * Uses the fetch API to obtain a token from identity
 */
export async function fetchNewToken(oldToken) {
	let sessionBlob;
	window.sessionStorage.clear();
	await fetch(`/graphql`, {
		method: 'POST',
		headers: {
			sessionBlob: oldToken,
		},
		body: '{"query":"query User {\\n  claims {\\n    atHomeMembership\\n    basicFitnessMembership\\n    canManageEnrollment\\n    connected\\n    connectedV2\\n    digitalWorkouts\\n    incentivesPlan\\n    incentivesPlanForWidget\\n    newsletters\\n    onlineClasses\\n    pointsPlan\\n    resourceLibrary\\n    socialClubs\\n    socialEvents\\n    canAttendVirtualEvents\\n    visitsPlan\\n    wellBeing\\n    __typename\\n  }\\n  clientDetails {\\n    clientName\\n    cobrandLogoUrl\\n    contract\\n    customizationImageRaw\\n    __typename\\n  }\\n  hostProperties {\\n    chatBotBold360AccountId\\n    chatBotBold360ChatId\\n    chatBotBold360WebsiteId\\n    chatBotEnabled\\n    digitalWorkoutsVideoCount\\n    enableABTestingOptimizeScript\\n    googleABTestingId\\n    googleAnalytics4Id\\n    googleAnalyticsCrossDomainLinks\\n    googleTagManagerId\\n    premiumFacilitiesCount\\n    standardFacilitiesCount\\n    __typename\\n  }\\n  footerConfig {\\n    copyright\\n    languageAssistancePath\\n    __typename\\n  }\\n  firstName\\n  fitnessId\\n  groupId\\n  lastName\\n}","operationName":"User","variables":{}}',
	})
		.then(response => response.json())
		.then(data => {
			sessionBlob = data.data.sessionBlob;
		})
		.catch(() => {
			throw new Error(`Fetching the token failed`);
		});

	return sessionBlob;
}

// Create the urql client
export function createUrqlClient() {
	return new Client({
		url: `https://${environmentPrefix}subscriptiongraphql.api.ashcompanies.com/graphql`,
		fetchOptions: {
			headers: {
				ssId,
			},
		},
		exchanges: [
			cacheExchange,
			dedupExchange,
			/**
			 * This exchange is provided by urql and helps us handle authorization life cycle.
			 */
			authExchange(async utils => {
				let token = getCachedToken();
				return {
					/**
					 * Adds the current token to each request we make
					 */
					addAuthToOperation(_operation) {
						logFrom(
							'addAuthToOperation',
							`Adding session token to header: ${token}`,
						);
						return utils.appendHeaders(_operation, {
							sessionBlob: token,
						});
					},
					// This will check if we have an existing sessionBlob
					// eslint-disable-next-line no-unused-vars
					willAuthError(_operation) {
						// FIX THIS
						if (getCachedToken() == null) return true;
						if (ssId !== getSsid()) {
							return true;
						}
						return false;
					},

					/**
					 * When it is determined that we need a new token, this function fetches it
					 */
					async refreshAuth() {
						try {
							const result = await fetchNewToken(token);
							// Update our local variables and write to our storage
							token = result;
							logFrom(
								`refreshAuth`,
								`Refreshing session blob with new value: ${token}`,
							);
							setCachedToken(token);
						} catch (e) {
							logFrom(
								'refreshAuth',
								'Session blob fetch failed, clearning session cache and would force navigate to the login page',
							);
							// This is where auth has gone wrong and we need to clean up and redirect to a login page
							clearCachedToken();
							// window.location.href = '/login';
						}
					},
				};
			}),
			fetchExchange,
		],
	});
}

export function maybeStartMocks() {
	if (
		process.env.NODE_ENV === 'development' &&
		!process.env.REACT_APP_PLAYWRIGHT
	) {
		Cookies.set('ss-id', '1234')
		// eslint-disable-next-line global-require
		const { worker } = require('./mocks/browser');
		worker.start();
	}
}
