import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'urql';
import CssBaseline from '@mui/material/CssBaseline';
import {
	createTheme,
	responsiveFontSizes,
	ThemeProvider,
} from '@mui/material/styles';
import { afd } from '@ashn/node-site-themes';
import reportWebVitals from './reportWebVitals';
import { createUrqlClient, maybeStartMocks } from './indexSupport';
import App from './App';
import QueryClientProvider from './data/ReactQueryProvider';

maybeStartMocks();

const theme = responsiveFontSizes(createTheme(afd));
// TODO: Remove after integrating eve-themes-react. This is done to address contrast issue.
theme.palette.error.main = '#D32F2F';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<React.StrictMode>
		<QueryClientProvider>
			<Provider value={createUrqlClient()}>
				<CssBaseline />
				<ThemeProvider theme={theme}>
					<HelmetProvider>
						<App />
					</HelmetProvider>
				</ThemeProvider>
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
